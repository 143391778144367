import React from "react"
import Layout from "../components/layout/layout"
import { useOwnershipData } from "../hooks/useOwnershipData"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import AvailableWeeks from "../components/ownership/availableWeeks"
import { withPreview } from "gatsby-source-prismic"
import ImageWithTextOver from "../components/slices/imageWithTextOver"
import Video from "../components/ownership/Video"
import TableWeeksForSale from "../components/ownership/table"

const sectionMapper = {
  header: PageHeader,
  video: Video,
  available_weeks: AvailableWeeks,
  image_with_text_over: ImageWithTextOver,
  applications: null,
  table: TableWeeksForSale
}

const Ownership = ({ data: previewData }) => {
  const { uid, sections, bookData, tellUsData } = useOwnershipData(previewData)
  
  return (
    <Layout bookBannerData={bookData} tellUsData={tellUsData}>
      <SEO title={uid} />
      {sections.map((section, i) => {
          const Component = sectionMapper[section.slice_type]
          return (
            <Component key={i} {...section.primary} items={section.items} />
          )
      })}
    </Layout>
  )
}

export default withPreview(Ownership)
